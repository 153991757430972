// useMarketStatus.js
import { useEffect, useState } from 'react';
import axios from 'axios';

export const useMarketStatus = (endpointURL) => {
  const [marketStatus, setMarketStatus] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(endpointURL);
        setMarketStatus(response.data.market_status);
      } catch (error) {
        setMarketStatus(false);
      }
    };

    fetchData();
  }, [endpointURL]);

  return marketStatus;
}
