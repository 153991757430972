import React, { useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import { styles } from "./calculator/calculatorStyle.js";
import { NumericFormat } from "react-number-format";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const Calculadora = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "testimonial section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "testimonial-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const sectionHeader = {
    title: "Calculadora",
    paragraph: "Previsualiza los valores de tu operacion antes de realizarla",
  };

  const [scopeCalculadora, setScopeCalculadora] = useState("exchange");
  const [selectedFrom, setSelectedFrom] = useState("ARS");
  const [selectedTo, setSelectedTo] = useState("USDT");
  const [valueFrom, setValueFrom] = useState("$0");
  const [valueTo, setValueTo] = useState("$0");
  const [inverted, setInverted] = useState(false);
  const [rates, setRates] = useState([]);
  const [saldos, setSaldos] = useState([]);
  const [valueFromNoFormat, setValueFromNoFormat] = useState(0);
  const [valueToNoFormat, setValueToNoFormat] = useState(0);
  const [, updateState] = useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const invertSelectedCurrencies = () => {
    setValuesToZero();
    setInverted(!inverted);
    const aux = selectedFrom;
    setSelectedFrom(selectedTo);
    setSelectedTo(aux);
    forceUpdate();
  };

  const setValuesToZero = () => {
    setValueFrom("$0");
    setValueTo("$0");
    setValueFromNoFormat(0);
    setValueToNoFormat(0);
  };

  const setScope = (scope) => {
    setScopeCalculadora(scope);
    if (scope === "exchange") {
      setSelectedFrom("ARS");
      setSelectedTo("USDT");
    } else {
      setSelectedFrom("Payoneer");
      setSelectedTo("USDT");
    }
    setValuesToZero();
  };

  const fetchRates = async () => {
    const response = await fetch(
      "https://api.calypso.exchange/v1/public/rates"
    );
    const data = await response.json();
    return data;
  };
  const fetchSaldos = async () => {
    const response = await fetch(
      "https://api.calypso.exchange/v1/public/saldos"
    );
    const data = await response.json();
    return data;
  };

  useEffect(() => {
    fetchRates().then((data) => {
      setRates(data);
    });
    fetchSaldos().then((data) => {
      setSaldos(data?.data);
    });
  }, []);

  const rates2 = useMemo(() => {
    const daiUsd = rates
      .filter((data) => data.pair.includes("USDT_USD"))
      .map((rate) => {
        const { buyPrice, sellPrice } = rate;
        return {
          pair: "DAI_USD",
          buyPrice,
          sellPrice,
        };
      });
    const daiArs = rates
      .filter((data) => data.pair.includes("USDT_ARS"))
      .map((rate) => {
        const { buyPrice, sellPrice } = rate;
        return {
          pair: "DAI_ARS",
          buyPrice,
          sellPrice,
        };
      });
    const usdcUsd = rates
      .filter((data) => data.pair.includes("USDT_USD"))
      .map((rate) => {
        const { buyPrice, sellPrice } = rate;
        return {
          pair: "USDC_USD",
          buyPrice,
          sellPrice,
        };
      });
    const usdcArs = rates
      .filter((data) => data.pair.includes("USDT_ARS"))
      .map((rate) => {
        const { buyPrice, sellPrice } = rate;
        return {
          pair: "USDC_ARS",
          buyPrice,
          sellPrice,
        };
      })
      .flat();

    const filteredRates = rates.concat(daiUsd, daiArs, usdcUsd, usdcArs);
    return filteredRates;
  }, [rates]);

  console.log("rates2", rates2);
  console.log("saldos", saldos);

  const calculateConversion = useCallback(
    (
      e,
      from = scopeCalculadora === "exchange" ? "ARS" : "Payoneer",
      to = "USDT",
      input = "from"
    ) => {
      let value = e;
      value = value = Number(
        value.replace(/[$]/g, "").replace(/[.]/g, "").replace(/[,]/g, ".")
      );
      let finalValue = 0;

      if (isNaN(value)) {
        value = 0;
      }

      // console.log("to", to);
      // console.log("from", from);

      if (scopeCalculadora === "exchange") {
        const fromCurrency = from;
        const toCurrency = to;

        const fromCurrencyRates = rates2.filter((rate) =>
          rate.pair.includes(fromCurrency)
        );
        const toCurrencyRates = rates2?.filter((rate) =>
          rate.pair.includes(toCurrency)
        );

        // console.log("fromCurrencyRates: 🟢", fromCurrencyRates);
        // console.log("toCurrencyRates: 🔴", toCurrencyRates);

        if (fromCurrency === "ARS") {
          if (
            toCurrency === "USDT" ||
            toCurrency === "DAI" ||
            toCurrency === "USDC"
          ) {
            if (input === "from") {
              finalValue = value / toCurrencyRates[1]?.sellPrice;
            } else {
              finalValue = value * fromCurrencyRates[3]?.sellPrice;
            }
          } else if (toCurrency === "USD") {
            if (input === "from") {
              finalValue = value / toCurrencyRates[5]?.sellPrice;
            } else {
              finalValue = value * toCurrencyRates[5]?.buyPrice;
            }
          }
        } else if (
          fromCurrency === "USDT" ||
          fromCurrency === "DAI" ||
          fromCurrency === "USDC"
        ) {
          if (toCurrency === "ARS") {
            if (input === "from") {
              finalValue = value * fromCurrencyRates[1]?.buyPrice;
            } else {
              finalValue = value / fromCurrencyRates[1]?.buyPrice;
            }
          } else if (toCurrency === "USD") {
            if (input === "from") {
              finalValue = value * fromCurrencyRates[0]?.buyPrice;
            } else {
              finalValue = value * fromCurrencyRates[0]?.buyPrice;
            }
          } else if (
            toCurrency === "USDT" ||
            toCurrency === "DAI" ||
            toCurrency === "USDC"
          ) {
            finalValue = value;
          }
        } else if (fromCurrency === "USD") {
          if (toCurrency === "ARS") {
            if (input === "from") {
              finalValue = value * fromCurrencyRates[5]?.sellPrice;
              finalValue = finalValue * fromCurrencyRates[3]?.buyPrice;
            } else {
              finalValue = value / fromCurrencyRates[3]?.buyPrice;
              finalValue = finalValue / fromCurrencyRates[5]?.sellPrice;
            }
          } else if (
            toCurrency === "USDT" ||
            toCurrency === "DAI" ||
            toCurrency === "USDC"
          ) {
            if (input === "from") {
              finalValue = value / toCurrencyRates[0]?.sellPrice;
            } else {
              finalValue = value * toCurrencyRates[0]?.sellPrice;
            }
          }
        }
        if (input === "from") {
          setValueFrom(
            `$${Number(value).toLocaleString("es-AR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`
          );
          setValueFromNoFormat(value);
          setValueTo(
            `$${Number(finalValue).toLocaleString("es-AR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`
          );
          setValueToNoFormat(finalValue);
        } else {
          setValueTo(
            `$${Number(value).toLocaleString("es-AR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`
          );
          setValueToNoFormat(value);
          setValueFrom(
            `$${Number(finalValue).toLocaleString("es-AR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`
          );
          setValueFromNoFormat(finalValue);
        }
      } else {
        if (from === "Payoneer") {
          if (to === "USDT" || to === "DAI" || to === "USDC") {
            if (input === "from") {
              finalValue = value - (value * Number(saldos[1]?.sellPrice)) / 100;
            } else {
              console.log("entra aca?");
              finalValue = value / Number(rates2[3]?.sellPrice);
            }
          }
        } else if (from === "Wise") {
          if (to === "USDT" || to === "DAI" || to === "USDC") {
            if (input === "from") {
              finalValue = value - (value * Number(saldos[2]?.sellPrice)) / 100;
            } else {
              finalValue = value / Number(rates2[3]?.sellPrice);
            }
          }
        } else if (from === "Cable") {
          if (to === "USDT" || to === "DAI" || to === "USDC") {
            if (input === "from") {
              finalValue = value - (value * Number(saldos[0]?.sellPrice)) / 100;
            } else {
              finalValue = value / Number(rates2[3]?.sellPrice);
            }
          }
        }
        if (input === "from") {
          setValueFrom(
            `$${Number(value).toLocaleString("es-AR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`
          );
          setValueFromNoFormat(value);
          setValueTo(
            `$${Number(finalValue).toLocaleString("es-AR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`
          );
          setValueToNoFormat(finalValue);
        } else {
          setValueTo(
            `$${Number(value).toLocaleString("es-AR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`
          );
          setValueToNoFormat(value);
          setValueFrom(
            `$${Number(finalValue).toLocaleString("es-AR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`
          );
          setValueFromNoFormat(finalValue);
        }
      }
    },

    [rates2, saldos, scopeCalculadora]
  );

  const selectFrom = (e) => {
    let value = e.target.value;
    setSelectedFrom(value);
    calculateConversion(valueFrom, value, selectedTo, "from");
  };

  const selectTo = (e) => {
    let value = e.target.value;
    setSelectedTo(value);
    calculateConversion(valueTo, selectedFrom, value, "to");
  };

  const dataCalculadora = {
    exchange: {
      fromCurrencies: ["USDT", "DAI", "USDC", "ARS", "USD"],
      toCurrencies: ["USDT", "DAI", "USDC", "ARS", "USD"],
    },
    saldos: {
      fromCurrencies: ["Payoneer", "Wise", "Cable"],
      toCurrencies: ["USDT"],
    },
  };

  const getFromOptions = () => {
    if (scopeCalculadora === "exchange") {
      const filtered = dataCalculadora.exchange.fromCurrencies.filter(
        (currency) => currency !== selectedTo
      );
      return filtered.map((currency) => {
        return (
          <option style={styles.option} value={currency}>
            {currency}
          </option>
        );
      });
    } else {
      return dataCalculadora.saldos.fromCurrencies.map((currency) => {
        return (
          <option style={styles.option} value={currency}>
            {currency}
          </option>
        );
      });
    }
  };

  const getToOptions = () => {
    if (scopeCalculadora === "exchange") {
      if (selectedFrom === "USD" || selectedFrom === "ARS") {
        return dataCalculadora.exchange.toCurrencies
          .filter(
            (currency) =>
              currency !== selectedFrom &&
              currency !== "USD" &&
              currency !== "ARS"
          )
          .map((currency) => {
            return (
              <option style={styles.option} value={currency}>
                {currency}
              </option>
            );
          });
      } else if (
        selectedFrom === "USDT" ||
        selectedFrom === "DAI" ||
        selectedFrom === "USDC"
      ) {
        return dataCalculadora.exchange.toCurrencies
          .filter((currency) => currency !== selectedFrom)
          .map((currency) => {
            return (
              <option style={styles.option} value={currency}>
                {currency}
              </option>
            );
          });
      } else {
        return (
          <option style={styles.option} value="ARS">
            ARS
          </option>
        );
      }
    } else {
      return dataCalculadora.saldos.toCurrencies.map((currency) => {
        return (
          <option style={styles.option} value={currency}>
            {currency}
          </option>
        );
      });
    }
  };

  return (
    <section {...props} className={outerClasses}>
      <div
        className="container"
        style={{ ...styles.calculatorContainer, marginTop: "100px" }}
      >
        <SectionHeader data={sectionHeader} className="center-content" />
        <div style={styles.calculatorContainer}>
          <div style={styles.scopeContainer}>
            <p
              style={{
                color: scopeCalculadora === "exchange" ? "#fc540d" : "#fff",
                fontWeight: scopeCalculadora === "exchange" ? 800 : 400,
                ...styles.scopeText,
              }}
              onClick={() => setScope("exchange")}
            >
              Exchange
            </p>
            <p
              style={{
                color: scopeCalculadora === "saldos" ? "#fc540d" : "#fff",
                fontWeight: scopeCalculadora === "saldos" ? 800 : 400,
                ...styles.scopeText,
              }}
              onClick={() => setScope("saldos")}
            >
              Saldos
            </p>
          </div>
          <div
            className="calculadoraContainer"
            style={styles.calculatorContainer}
          >
            <div id="fromCurrency" style={styles.fromCurrency}>
              <div id="fromInput" style={styles.fromInput}>
                {scopeCalculadora === "exchange" ? (
                  <p style={styles.fromInputPlaceholder}>Envias:</p>
                ) : (
                  <p style={styles.fromInputPlaceholder}>Saldo en:</p>
                )}
                <NumericFormat
                  onChange={(e) => {
                    let value = e.target.value;
                    calculateConversion(
                      value,
                      selectedFrom,
                      selectedTo,
                      "from"
                    );
                  }}
                  prefix="$"
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  style={styles.fromInputInput}
                  value={valueFromNoFormat}
                  placeholder="$0"
                />
              </div>
              <div id="fromDropdown" style={{ margin: "auto" }}>
                <select
                  value={selectedFrom}
                  style={
                    scopeCalculadora === "saldos"
                      ? {
                          ...styles.fromDropdown,
                          width: "130px",
                          right: "18px",
                          position: "relative",
                        }
                      : styles.fromDropdown
                  }
                  onChange={(e) => selectFrom(e)}
                >
                  {getFromOptions()}
                </select>
              </div>
            </div>
            {scopeCalculadora === "exchange" ? (
              <div style={styles.arrowIcon} onClick={invertSelectedCurrencies}>
                <svg
                  id="Layer_1"
                  data-name="Layer 1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 122.88 111.42"
                  height="32px"
                  width="32px"
                >
                  <title>compare</title>
                  <path d="M29.73,17.66H0V47.79H29.73V59.28c.21,5,3.88,6.78,8.42,5.25a2.47,2.47,0,0,0,.7-.37c11.47-9,19.37-18,30.84-27l.16-.13c3.49-3.18,2.46-6.8-.93-9.47L40.81,2.61A13.25,13.25,0,0,0,36.65.28C34-.51,31.54.37,30.41,3a10,10,0,0,0-.68,3.93c0,3.57-.05,7.16,0,10.73ZM93.15,64h29.73V94.16H93.15v11.48c-.21,5-3.88,6.79-8.42,5.25a2.24,2.24,0,0,1-.7-.37c-11.47-9-19.37-18-30.84-27L53,83.38c-3.49-3.17-2.46-6.8.93-9.46L82.07,49a13,13,0,0,1,4.16-2.32c2.66-.8,5.11.08,6.24,2.71a10,10,0,0,1,.68,3.94c0,3.56.06,7.16,0,10.72Z" />
                </svg>
              </div>
            ) : (
              <div style={styles.arrowIcon}>
                <div style={{ height: 32 }}></div>
              </div>
            )}
            <div id="toCurrency" style={styles.toCurrency}>
              <div id="toInputDiv" style={styles.fromInput}>
                <p style={styles.fromInputPlaceholder}>Recibis:</p>
                <NumericFormat
                  onChange={(e) => {
                    let value = e.target.value;
                    calculateConversion(value, selectedFrom, selectedTo, "to");
                  }}
                  prefix="$"
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  style={styles.fromInputInput}
                  value={valueToNoFormat}
                  placeholder="$0"
                />
              </div>
              <div id="fromDropdown" style={{ margin: "auto" }}>
                <select
                  value={selectedTo}
                  style={styles.fromDropdown}
                  onChange={(e) => selectTo(e)}
                >
                  {getToOptions()}
                </select>
              </div>
            </div>

            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://t.me/SoporteCalypso"
            >
              <div style={styles.callToAction}>Operá!</div>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

Calculadora.propTypes = propTypes;
Calculadora.defaultProps = defaultProps;

export default Calculadora;
