import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import { Link } from "react-scroll";
import { useMarketStatus } from "../../hooks/useMarketStatus";
import getRates from "./hooks/useRates";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const Exchange = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const baseUrl = "https://api.calypso.exchange";
  const market = useMarketStatus(`${baseUrl}/v1/public/features`);
  // const market = false;
  const outerClasses = classNames(
    "testimonial section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    className
  );

  const innerClasses = classNames(
    "testimonial-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider",
    !market && "blurdiv"
  );

  const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

  const sectionHeader = {
    title: "Exchange",
    paragraph:
      "Los precios pueden estar sujetos a cambios Comunicate con nosotros ante cualquier consulta",
  };

  const [currency, setCurrency] = useState("_ARS");
  const [rates, setRates] = useState([]);
  const url = "https://api.calypso.exchange/v1/public/rates";
  const { rates2 } = getRates(rates);

  useEffect(() => {
    fetch(url)
      .then((res) => res.json())
      .then((data) => setRates(data));
  }, []);

  function numberWithCommas(x) {
    return x.toLocaleString("es-AR", {
      minimumFractionDigits: currency === "_ARS" ? 2 : 4,
    });
  }

  // concat to the array 4 objects with the same buyPrice and sellPrice, but one with the pair DAI_ARS, other DAI_USD, other USDC_ARS, other USDC_USD

  const arsDisclaimer = (
    <p
      style={{
        fontFamily: "Nunito, sans-serif",
        fontSize: "16px",
        color: "#11111",
        textAlign: "center",
        marginTop: "20px",
        marginBottom: "20px",
      }}
    >
      {" "}
      Los precios reflejados en pesos son para operaciones via transferencia
      bancaria. Para cotizaciones cash consultar a los operadores.
    </p>
  );

  // Filter de array to exclude all the pairs that doesn't include USDT, BUSD, DAI or USDC

  const newRates = rates2
    ?.filter((data) => data?.pair?.includes(currency))
    .filter(
      (data) =>
        data?.pair?.includes("USDT") ||
        data?.pair?.includes("USDC") ||
        data?.pair?.includes("DAI")
    )
    .sort((a, b) => b.pair.localeCompare(a.pair))
    .map((val) => {
      return (
        <div className="tiles-item" data-reveal-delay="200">
          <div style={{ borderRadius: 20 }} className="tiles-item-inner">
            <div
              style={{
                paddingRight: 5,
                paddingLeft: 5,
                paddingBottom: 20,
              }}
              className=""
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <img
                  style={{ height: 22, width: 22, marginRight: 10 }}
                  src={require(`../../assets/images/${val.pair.substring(
                    0,
                    4
                  )}.png`)}
                />
                <h4>{val.pair.replace("_", "/")}</h4>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <p style={{ marginBottom: 0, fontSize: 14, color: "#fff" }}>
                    Compra a:
                  </p>
                  <p style={{ color: "#fff" }}>
                    ${numberWithCommas(parseFloat(val.sellPrice))}
                  </p>
                </div>
                <div>
                  <p style={{ marginBottom: 0, fontSize: 14, color: "#fff" }}>
                    Vende a:
                  </p>
                  <p style={{ color: "#fff" }}>
                    ${numberWithCommas(parseFloat(val.buyPrice))}
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <a
                  className="button button-primary button-margin"
                  color="primary"
                  wideMobile
                  href="#contact"
                  style={{
                    paddingLeft: 20,
                    paddingRight: 20,
                    paddingTop: 4,
                    height: 35,
                  }}
                >
                  <Link smooth={true} to="contact">
                    Comprá
                  </Link>
                </a>
                <a
                  className="button"
                  color="primary"
                  wideMobile
                  href="#contact"
                  style={{
                    paddingLeft: 20,
                    paddingRight: 20,
                    paddingTop: 4,
                    height: 35,
                  }}
                >
                  <Link style={{ color: "#000" }} smooth={true} to="contact">
                    Vendé
                  </Link>
                </a>
              </div>
            </div>
          </div>
        </div>
      );
    });

  return (
    <section {...props} className={outerClasses}>
      {!market && (
        <div className="timerContainer">
          <div className="timerContainerInner">
            {/* <p className="closedMarketNotice">Exchange disponible de Lunes a Viernes de 11am - 6pm</p> */}
            {/* <p className="closedMarketFooter">Mientras tanto sumate a nuestra comunidad</p> */}
            <p className="closedMarketNotice">
              Exchange no disponible temporalmente
            </p>
            <p className="closedMarketFooter">
              Sumate a nuestras comunidades para novedades
            </p>
            <div className="closedMarketSocialMedia">
              <a
                href="https://www.instagram.com/calypso.exchange"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.22 0H12.78C15.66 0 18 2.34 18 5.22V12.78C18 14.1644 17.45 15.4922 16.4711 16.4711C15.4922 17.45 14.1644 18 12.78 18H5.22C2.34 18 0 15.66 0 12.78V5.22C0 3.83557 0.549963 2.50784 1.5289 1.5289C2.50784 0.549963 3.83557 0 5.22 0ZM5.04 1.8C4.1807 1.8 3.35659 2.14136 2.74897 2.74897C2.14136 3.35659 1.8 4.1807 1.8 5.04V12.96C1.8 14.751 3.249 16.2 5.04 16.2H12.96C13.8193 16.2 14.6434 15.8586 15.251 15.251C15.8586 14.6434 16.2 13.8193 16.2 12.96V5.04C16.2 3.249 14.751 1.8 12.96 1.8H5.04ZM13.725 3.15C14.0234 3.15 14.3095 3.26853 14.5205 3.4795C14.7315 3.69048 14.85 3.97663 14.85 4.275C14.85 4.57337 14.7315 4.85952 14.5205 5.0705C14.3095 5.28147 14.0234 5.4 13.725 5.4C13.4266 5.4 13.1405 5.28147 12.9295 5.0705C12.7185 4.85952 12.6 4.57337 12.6 4.275C12.6 3.97663 12.7185 3.69048 12.9295 3.4795C13.1405 3.26853 13.4266 3.15 13.725 3.15ZM9 4.5C10.1935 4.5 11.3381 4.97411 12.182 5.81802C13.0259 6.66193 13.5 7.80653 13.5 9C13.5 10.1935 13.0259 11.3381 12.182 12.182C11.3381 13.0259 10.1935 13.5 9 13.5C7.80653 13.5 6.66193 13.0259 5.81802 12.182C4.97411 11.3381 4.5 10.1935 4.5 9C4.5 7.80653 4.97411 6.66193 5.81802 5.81802C6.66193 4.97411 7.80653 4.5 9 4.5ZM9 6.3C8.28392 6.3 7.59716 6.58446 7.09081 7.09081C6.58446 7.59716 6.3 8.28392 6.3 9C6.3 9.71608 6.58446 10.4028 7.09081 10.9092C7.59716 11.4155 8.28392 11.7 9 11.7C9.71608 11.7 10.4028 11.4155 10.9092 10.9092C11.4155 10.4028 11.7 9.71608 11.7 9C11.7 8.28392 11.4155 7.59716 10.9092 7.09081C10.4028 6.58446 9.71608 6.3 9 6.3Z"
                    fill="white"
                  />
                </svg>
              </a>
              <div className="closedMarketSeparator"></div>
              <a
                href="https://www.x.com/CalypsoExchange"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  width="20"
                  height="18"
                  viewBox="0 0 20 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.7512 0H18.818L12.1179 7.62462L20 18H13.8275L8.99365 11.7074L3.46266 18H0.394011L7.56038 9.84461L0 0H6.32735L10.6967 5.75169L15.7493 0H15.7512ZM14.6748 16.1723H16.3742L5.4049 1.73169H3.58133L14.6748 16.1723Z"
                    fill="white"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      )}
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />

          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginBottom: 20,
                backgroundColor: "#121212",
                borderRadius: 15,
                paddingLeft: 10,
                paddingRight: 10,
                width: "auto",
              }}
            >
              <p
                style={{
                  margin: 10,
                  color: currency === "_ARS" ? "#fc540d" : "white",
                  fontWeight: currency === "_ARS" ? 600 : 400,
                  cursor: "pointer",
                  transition: "0.3s",
                }}
                onClick={() => setCurrency("_ARS")}
              >
                ARS
              </p>
              <p
                style={{
                  margin: 10,
                  color: currency === "_USD" ? "#fc540d" : "white",
                  fontWeight: currency === "_USD" ? 600 : 400,
                  cursor: "pointer",
                  transition: "0.3s",
                }}
                onClick={() => setCurrency("_USD")}
              >
                USD
              </p>
              {/* <p
                style={{
                  margin: 10,
                  color: currency === "_EUR" ? "#fc540d" : "white",
                  fontWeight: currency === "_EUR" ? 600 : 400,
                  cursor: "pointer",
                  transition: "0.3s",
                }}
                onClick={() => setCurrency("_EUR")}
              >
                EUR
              </p> */}
            </div>
          </div>
          <div className={tilesClasses}>
            {rates.length > 1 ? newRates : null}
          </div>
          {currency === "_ARS" ? arsDisclaimer : null}
        </div>
      </div>
    </section>
  );
};

Exchange.propTypes = propTypes;
Exchange.defaultProps = defaultProps;

export default Exchange;
