import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const Testimonial = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "testimonial section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "testimonial-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

  const sectionHeader = {
    title: "Opiniones",
    paragraph:
      "En Calypso nos esforzamos para brindar confianza y seguridad a nuestros clientes en cada operación",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
            <img
              className="heroImage2"
              src={require(`../../assets/images/shapes2.png`)}
            />
            <div
              className="tiles-item reveal-from-right"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                    — Siempre salió todo perfecto, cada vez que me contacte con
                    ellos me contestaron en cuestión de minutos y nunca hubo un
                    problema a la hora de coordinar alguna operación. Más que
                    agradecido con ellos y súper recomendable para todo aquel
                    que se quiera meter en el mundo P2P.
                  </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">
                    Opinión en
                  </span>
                  <span className="text-color-low"> </span>
                  <span className="testimonial-item-link">
                    <a href="https://www.cryptosaurio.com/ar/opiniones/calypsop2p/mas-recientes/9">
                      Cryptosaurio.com
                    </a>
                  </span>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                    — Opero con Calypso desde 2019, jamás un problema, siempre
                    dispuestos a darte el mejor precio posible y ayudarte con
                    todo lo referido a cripto. Si no podes acercarte a la
                    oficina, te mandan el cash a domicilio. Unos cracks!
                  </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">
                    Opinión en
                  </span>
                  <span className="text-color-low"> </span>
                  <span className="testimonial-item-link">
                    <a
                      href="https://www.cryptosaurio.com/ar/opiniones/calypsop2p/mas-recientes/9"
                      target="_blank"
                    >
                      Cryptosaurio.com
                    </a>
                  </span>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-left"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0">
                    — Hace meses uso calypso. Nunca tuve un problema, siempre el
                    trato es cordial y transparente. Tienen buena cobertura de
                    zonas y manejan una excelente comision. Se lo recomiendo a
                    cada persona que necesita tener los billetes o la crypto al
                    momento.
                  </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">
                    Opinión en
                  </span>
                  <span className="text-color-low"> </span>
                  <span className="testimonial-item-link">
                    <a
                      href="https://www.cryptosaurio.com/ar/opiniones/calypsop2p/mas-recientes/19"
                      target="_blank"
                    >
                      Cryptosaurio.com
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;
