import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import { useSaldosValues } from "../../hooks/useSaldosStatus";
import { Link } from "react-scroll";
import { svgComponentMap } from "../../utils/SvgComponents";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const Saldos = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const baseUrl = "https://api.calypso.exchange";
  const saldosStatus = true;
  const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

  const outerClasses = classNames(
    "testimonial section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "testimonial-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const sectionHeader = {
    title: "Saldos",
    paragraph:
      "Intercambia tus saldos de las plataformas más importantes del mercado",
  };
  const saldosData = useSaldosValues(`${baseUrl}/v1/public/saldos`);

  const newSaldos = saldosData.map((val) => {
    // cada uno de elementos del array
    return (
      <div className="tiles-item" data-reveal-delay="200">
        <div style={{ borderRadius: 20 }} className="tiles-item-inner">
          <div
            style={{
              paddingRight: 5,
              paddingLeft: 5,
              paddingBottom: 20,
            }}
            className=""
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {svgComponentMap[val.logo]}
              <h4>{val.name}</h4>
            </div>
            <div
              id="div1"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <div
                id="div2"
                style={{
                  marginBottom: "24px",
                  width: "35%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <p style={{ marginBottom: 0, fontSize: 14, color: "#fff" }}>
                  USD/USDT
                </p>
                <p
                  style={{ color: "#fff", fontSize: "32px", fontWeight: "800" }}
                >
                  {val.sellPrice}%
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <a
                className="button button-primary"
                color="primary"
                wideMobile
                href="#contact"
                style={{
                  paddingLeft: 20,
                  paddingRight: 20,
                  paddingTop: 4,
                  height: 35,
                  width: "70%",
                }}
              >
                <Link smooth={true} to="contact">
                  Vendé
                </Link>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  });
  return (
    <section {...props} className={outerClasses}>
      {saldosStatus && (
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div style={{ display: "flex", justifyContent: "center" }}></div>
            <div className={tilesClasses}>{newSaldos}</div>
          </div>
          <p
            style={{
              fontFamily: "Nunito, sans-serif",
              fontSize: "16px",
              color: "#11111",
              textAlign: "center",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            Para liquidar saldos de cable/payoneer/wise contra FIAT, comunicarse
            con un operador.
          </p>
        </div>
      )}
    </section>
  );
};

Saldos.propTypes = propTypes;
Saldos.defaultProps = defaultProps;

export default Saldos;
