export const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: 20,
    backgroundColor: "#121212",
    borderRadius: 15,
    paddingLeft: 10,
    paddingRight: 10,
    width: "100%",
    margin: "auto",
    position: "relative",
  },
  scopeContainer: { display: "flex", flexDirection: "row", margin: "auto" },
  scopeText: {
    margin: 10,
    cursor: "pointer",
    transition: "0.3s",
  },
  calculatorContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: "auto",
    width: "100%",
    maxWidth: "400px",
  },
  fromCurrency: {
    width: "100%",
    height: "84px",
    flexShrink: "0",
    borderRadius: "10px",
    background: "#2B2B2B",
    marginBottom: "-28px",
    display: "flex",
    flexDirection: "row",
  },
  toCurrency: {
    width: "100%",
    height: "84px",
    flexShrink: "0",
    borderRadius: "10px",
    background: "#2B2B2B",
    marginBottom: "4px",
    display: "flex",
    flexDirection: "row",
  },
  fromInput: { marginLeft: "15px", marginTop: "7px", width: "60%" },
  fromInputPlaceholder: { color: "#fff", margin: "auto", fontSize: "14px" },
  fromInputInput: {
    background: "none",
    outline: "none",
    border: "none",
    marginTop: "2px",
    fontSize: "24px",
    color: "#fff",
  },
  fromDropdown: {
    cursor: "pointer",
    background: "#3a3a3a",
    outline: "none",
    border: "none",
    borderRadius: "10px",
    width: "100px",
    height: "42px",
    alignItems: "center",
    paddingLeft: "10px",
    paddingRight: "10px",
    color: "#fff",
  },
  option: {
    background: "none",
    outline: "none",
    border: "none",
    borderRadius: "10px",
    width: "86px",
    height: "42px",
    alignItems: "center",
    paddingRight: "10px",
    color: "#fff",
  },
  callToAction: {
    cursor: "pointer",
    marginBottom: "20px",
    display: "flex",
    width: "100%",
    padding: "12px 20px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "10px",
    background:
      "var(--gradient-btn, linear-gradient(119deg, #FF0005 14.1%, #FF7C00 53%, #FFEEC8 90.3%))",
  },
  arrowIcon: {
    cursor: "pointer",
    fill: "#fff",
    zIndex: "1",
    top: "15px",
    right: "44px",
    alignSelf: "flex-end",
    position: "relative",
  },
};
