import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const Partners = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "testimonial section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "testimonial-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

  const sectionHeader = {
    title: "Nuestros partners",
    paragraph: "",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          {/* <SectionHeader data={sectionHeader} className="center-content" /> */}
          <div
            style={{
              height: 50,
              weight: 50,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              marginBottom: 60,
              flexWrap: "wrap",
            }}
          >
            <a
              href="https://www.viajobien.com/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                alt="Viajobien"
                className="cryptoAvisos"
                src={require("../../assets/images/viajobien.png")}
              />
            </a>
            {/* <img className="cryptoAvisos" src={require("../../assets/images/newells.png")} /> */}
            <a
              href="https://futura.haus/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                alt="CryptoAvisos"
                className="cryptoAvisos"
                src={require("../../assets/images/futura.png")}
              />
            </a>
            <a
              href="https://www.cryptosaurio.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                alt="Cryptosaurio"
                className="cryptoAvisos"
                src={require("../../assets/images/criptoplace.png")}
              />
            </a>
            <a
              href="https://www.cryptoavisos.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                alt="CryptoAvisos"
                className="cryptoAvisos"
                src={require("../../assets/images/cryptoavisos.png")}
              />
            </a>
            <a
              href="https://www.defiantapp.tech"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                alt="Defiant App"
                className="cryptoAvisos"
                src={require("../../assets/images/defiant.png")}
              />
            </a>
            <a
              href="https://linktr.ee/ditelstore/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                alt="Ditel Store"
                className="cryptoAvisos"
                src={require("../../assets/images/ditelstore.png")}
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

Partners.propTypes = propTypes;
Partners.defaultProps = defaultProps;

export default Partners;
