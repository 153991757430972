import React, {useState, useEffect} from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import BlogSection from '../components/sections/BlogSection';
import Testimonial from '../components/sections/Testimonial';
import Cta from '../components/sections/Cta';
import Exchange from '../components/sections/Exchange';
import Contact from '../components/sections/Contact';
import Partners from '../components/sections/Partners';
import Saldos from '../components/sections/Saldos';
import Calculadora from '../components/sections/Calculadora';
import { useSaldosValues } from '../hooks/useSaldosStatus';

const Home = () => {

  //TODO: refactor this and make an utils function
  //TODO: View useMemo hook
  const [rates, setRates] = useState([]);
  const url = "https://api.calypso.exchange/v1/public/rates";

  useEffect(() => {
    fetch(url)
      .then((res) => res.json())
      .then((data) => setRates(data));
  }, []);
  const saldos = useSaldosValues(`https://api.calypso.exchange/v1/public/saldos`);

  return (
    <>
      <Hero className="illustration-section-01" />
      <Exchange id="exchange"/>
      <Saldos id="saldos"/>
      <Calculadora id="calculadora" rates={rates} saldos={saldos}/>      
      <BlogSection />
      <Testimonial className="testimonials" id="testimonials" topDivider />
      <Partners />
      <Contact id="contact" />
    </>
  );
}

export default Home;