import React from "react";
import classNames from "classnames";
import { Link, animateScroll as scroll } from "react-scroll";

const FooterNav = ({ className, ...props }) => {
  const classes = classNames("footer-nav", className);

  return (
    <nav {...props} className={classes}>
      <ul className="list-reset">
        <li>
          <a
            href="https://t.me/SoporteCalypso"
            target="_blank"
            rel="noopener noreferrer"
          >
            OTC
          </a>
        </li>
        <li>
          <a
            href="https://t.me/SoporteCalypso"
            target="_blank"
            rel="noopener noreferrer"
          >
            Soporte
          </a>
        </li>
        <li>
          <Link smooth={true} to="contact">
            Contacto
          </Link>
        </li>
        <li>
          <Link offset={50} smooth={true} to="exchange">
            Cotizaciones
          </Link>
        </li>
        <li>
          <Link offset={50} smooth={true} to="testimonials">
            Opiniones
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default FooterNav;
