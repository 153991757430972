// useMarketStatus.js
import { useEffect, useState } from "react";
import axios from "axios";

export const useSaldosStatus = (endpointURL) => {
  const [saldosStatus, setSaldosStatus] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(endpointURL);
        setSaldosStatus(response.data.saldos_status);
      } catch (error) {
        setSaldosStatus(false);
      }
    };

    fetchData();
  }, [endpointURL]);

  return saldosStatus;
};

export const useSaldosValues = (endpointURL) => {
  const [saldosValues, setSaldosValues] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(endpointURL);
        setSaldosValues(response.data.data);
      } catch (error) {
        setSaldosValues([]);
      }
    };

    fetchData();
  }, [endpointURL]);

  return saldosValues;
};
