const getRates = (rates) => {
  const daiUsd = rates
    .filter((data) => data.pair.includes("USDT_USD"))
    .map((rate) => {
      const { buyPrice, sellPrice } = rate;
      return {
        pair: "DAI_USD",
        buyPrice,
        sellPrice,
      };
    });
  const daiArs = rates
    .filter((data) => data.pair.includes("USDT_ARS"))
    .map((rate) => {
      const { buyPrice, sellPrice } = rate;
      return {
        pair: "DAI_ARS",
        buyPrice,
        sellPrice,
      };
    });
  // const busdUsd = rates
  //   .filter((data) => data.pair.includes("USDT_USD"))
  //   .map((rate) => {
  //     const { buyPrice, sellPrice } = rate;
  //     return {
  //       pair: "BUSD_USD",
  //       buyPrice,
  //       sellPrice,
  //     };
  //   });
  // const busdArs = rates
  //   .filter((data) => data.pair.includes("USDT_ARS"))
  //   .map((rate) => {
  //     const { buyPrice, sellPrice } = rate;
  //     return {
  //       pair: "BUSD_ARS",
  //       buyPrice,
  //       sellPrice,
  //     };
  //   });
  const usdcUsd = rates
    .filter((data) => data.pair.includes("USDT_USD"))
    .map((rate) => {
      const { buyPrice, sellPrice } = rate;
      return {
        pair: "USDC_USD",
        buyPrice,
        sellPrice,
      };
    });
  const usdcArs = rates
    .filter((data) => data.pair.includes("USDT_ARS"))
    .map((rate) => {
      const { buyPrice, sellPrice } = rate;
      return {
        pair: "USDC_ARS",
        buyPrice,
        sellPrice,
      };
    })
    .flat();

  const rates2 = rates.concat(
    daiUsd,
    daiArs,
    usdcUsd,
    usdcArs
    // busdUsd,
    // busdArs
  );

  return {
    rates2,
  };
};

export default getRates;
